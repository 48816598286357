<template>
  <div>
    <solution-header
      :body="body"
      :image="image"
      :show-buy-btn="showBuyBtn"
      :show-detail-btn="showDetailBtn"
      :title="title"
    />
    <!-- <ImageList :images="images"></ImageList> -->

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
      :title="`行业背景`"
      :en_title="`Industry Background`"
    ></Paragraph>
    <Titlestrlist :datalist="datalist" style="margin-left: 15%"></Titlestrlist>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/map.svg`)"
      :title="`行业痛点`"
      :en_title="`Industry Pain Points`"
    ></Paragraph>
    <el-row style="margin: 0 10%">
      <el-col :span="12">
        <Titlecontent :data="datalist1[0]"></Titlecontent>
        <Titlecontent :data="datalist1[1]"></Titlecontent>
      </el-col>
      <el-col :span="12">
        <Titlecontent :data="datalist1[2]"></Titlecontent>
      </el-col>
    </el-row>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/star.svg`)"
      :title="`方案优势`"
      :en_title="`Solution Advantage`"
    ></Paragraph>
    <Logotitleboxwtc :datalist="datalist2"></Logotitleboxwtc>
    <Logotitleboxwtc :datalist="datalist3"></Logotitleboxwtc>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
      :title="`方案价值`"
      :en_title="`Solution Value`"
    ></Paragraph>
    <el-row style="margin: 0 10%">
      <el-col :span="7">
        <Titlecontent :data="datalist4[0]"></Titlecontent>
        <Titlecontent :data="datalist4[1]"></Titlecontent>
      </el-col>
      <el-col :span="8">
        <Titlecontent :data="datalist4[2]"></Titlecontent>
        <Titlecontent :data="datalist4[3]"></Titlecontent>
      </el-col>
      <el-col :span="9">
        <Titlecontent :data="datalist4[4]"></Titlecontent>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import ImageList from '@/components/common/ImageList';
import SolutionHeader from '@/components/common/SolutionHeader.vue';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Logotitleboxwtc from '@/components/chain/basic/common/logotitleboxwtc';
import Titlestrlist from '@/components/chain/basic/common/titlestrlist';
import Titlecontent from '@/components/chain/basic/common/titlecontent';

export default {
  name: 'BaaSLeaflet',
  components: {
    // ImageList,
    SolutionHeader,
    Paragraph,
    Logotitleboxwtc,
    Titlestrlist,
    Titlecontent,
  },
  data() {
    return {
      title: '区块链+房地产解决方案',
      body:
        '可信房产中介服务管理平台是基于区块链的智慧房屋交易及住房租赁管理平台，'
        + '对房屋租售全流程包括房源信息、身份信息、合同、机制管理及交易信息等进行管理；并以住房租赁管理平台为依托，'
        + '构建住房租赁积分系统和数字资产管理体系，通过数字资产链接不同消费场景，最终实现数字资产兑付。',
      image: require('@/assets/image/solution/landed/head.svg'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/solution/landed/25_01.jpg'),
        require('@/assets/image/solution/landed/25_02.jpg'),
        require('@/assets/image/solution/landed/25_03.jpg'),
      ],
      datalist: [
        {
          title: '> ',
          content: '房产中介平台缺少低成本获取真实房源的渠道和方式',
        },
        {
          title: '> ',
          content: '中介平台缺少低价获取客源的渠道/签章、时间戳等服务。',
        },
        {
          title: '> ',
          content: '买卖双方或租赁双方交易完后与平台再无互动，缺乏活跃度',
        },
        {
          title: '> ',
          content: '房价透明，中介之间缺乏优势，转化困难',
        },
        {
          title: '> ',
          content: '房产交易流程复杂，线下环节过多，合同签约及备案麻烦',
        },
        {
          title: '> ',
          content: '除了房产经纪人传播房产信息，缺乏有效自传播机制',
        },
        {
          title: '> ',
          content: '中介平台对房产经纪人、及各资源提供方的激励机制有待改进',
        },
        {
          title: '> ',
          content: '互联网中介平台增多，线上线下结合，缺乏突破性技术和模式创新',
        },
      ],
      datalist1: [
        {
          title: ['新房交易'],
          content:
            '认购金可能直接落入开发商账户，政府机构无从监管，购房者无处追溯与查询。'
            + '合同签订后，相关政府部门无法及时获取交易信息，存在开发商虚假宣传、一房多卖的风险，损害购房者的利益。'
            + '贷款合同签订后，银行机构无法及时将贷款主体信息及时提供给房管部门进行合同备案，需要购房者跑腿至房管部门进行抵押登记，影响业务办理效率。',
        },
        {
          title: ['二手房交易'],
          content:
            '经纪人隐瞒房源真实信息，诸如虚报房屋面积、产权不清晰、土地性质不明确等，甚至隐瞒抵押、查封等限制交易的情况。\
            经纪人私自收取代办费、担保费等，或非法挪用买方首付款。\
            合同内容不够明晰：对定金、首付款等费用以及违约条款没有明确规定。',
        },
        {
          title: ['房屋租赁'],
          content:
            '虚假信息：经纪人为吸引顾客，虚假房源信息，平台更新信息不及时，增加用户沟通成本；\
            信息不透明：租房者无法获知出租房源的历史出租情况、装修情况、甲醛指标等关键信息，可能危及租房者的身心利益。\
            交易不规范：部分经纪人存在私下收取定金、押金等不规范行为。\
            资金流难追溯：部分租赁运营公司通过租金贷等手段获取银行贷款并捐钱跑路，导致房主无法按时获取房租，万不得已收回房屋，租客无家可归，金融机构蒙受损失。这主要归咎于租客提交的分期资金与银行垫付的租金等资金无从追溯与监管。\
            信息孤立，无法整合：租赁交易信息、合同信息、资金流向信息数据分散，无法统一汇总，政府监管难。\
            租后服务不到位：水、电、天然气等缴费不便，转租续租等流程不明确，运营管理电子化程度低。\
            房屋纠纷投诉：无有效便利渠道找解决方案，相关合约资料可能丢失，或者难以获得。',
        },
      ],
      datalist2: [
        {
          title: '信息真实可信',
          list: '',
        },
        {
          title: '信息整合与共享',
          list: '',
        },
        {
          title: '提升业务协同效率',
          list: '',
        },
      ],
      datalist3: [
        {
          title: '降低交易金融风险',
          list: '',
        },
        {
          title: '提升政府监管广度与深度',
          list: '',
        },
        {
          title: '融合业务流与资金流',
          list: '',
        },
        {
          title: '构建可信安全的房产行业生态',
          list: '',
        },
      ],
      datalist4: [
        {
          title: ['解决房源', '虚假信息问题'],
          content:
            '通过区块链网络，获取政府部门数据接口，房产交易平台可以验证房源信息、买卖方及租方身份信息和资格信息，杜绝虚假信息。',
        },
        {
          title: ['解决房产交易多参与', '方业务协同难的问题'],
          content:
            '基于区块链智能合约，使得复杂业务场景可编程化，满足不同业务场景的定制化需求，实现平台各方实时共享与快速确认，从而提升参与方与复杂业务流程的协同效率。',
        },
        {
          title: ['解决房产交易流程复杂、', '买房跑腿多的问题'],
          content:
            '将房屋租售流程线上化，整个流程顺序有系统控制，清晰合理，省去不必要的线下跑腿过程。比如，新房买卖过程，买方在办理抵押贷款后，信息可同步通过区块链网络传递到房管局相关部门进行贷款合同备案，减少跑腿次数。',
        },
        {
          title: ['解决政府对房产', '市场监管难的问题'],
          content:
            '政府可联通区块链，获取交易信息，减少房产交易中的不合规，实现全领域全生命周期监管。',
        },
        {
          title: ['解决银行资金流和不动产交易', '业务流信息隔离的问题'],
          content:
            '例如，在房屋租赁交易过程中，银行通过租金分期贷款等方式向承租方提供金融服务，主要的金融风险为交易主体是否能按时还款，与不法分子通过虚假房源、虚假交易进行骗贷。区块链链接银行、政府与房屋租赁平台，银行可获取经过政府验证的真实房源信息及租赁人的身份信息，为确保业务真实可靠加上强保险，从而降低银行业务风险，方便金融服务的展开。',
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
