<template>
  <div style="margin-top: 2rem; margin-left: 10%; margin-right: 10%; text-align: left">
    <ul style="list-style: none; padding: 0">
      <!-- <li
        v-for="item in datalist"
        :key="item.index"
        class="hf"
        style="
          text-align: left;
          color: #333333;
          font-size: 1.4rem;
          font-weight: 400;
          font-family: PingFang SC Medium, PingFang SC Medium-Medium;
          letter-spacing: 0.2rem;
          line-height: 2rem;
        "
      > -->

      <div
        style="
          margin-top: 1rem;
          margin-bottom: 1rem;
          display: flex;
          flex-direction: column;
          font-size: 1.2rem;
          align-items: center;
          color: #686868;
          white-space: nowrap;
        "
      >
        <!-- <p
          v-for="citem in item.list"
          :key="citem.index"
          style="padding: 0.2rem; margin: 0"
          v-html="citem"
        ></p> -->
        <span
          v-for="item in data.title"
          :key="item.index"
          style="
            font-weight: 400;
            color: #2285ff;
            font-size: 1.5rem;
            margin-right: 1rem;
          "
          v-html="item"
        ></span>
      </div>
      <p>{{ data.content }}</p>
      <!-- </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  name: 'titlecontent',
  props: ['data'],
  methods: {},
  created() {
    const list = document.getElementsByClassName('hf');
    for (let i = 0; i < list.length; i += 1) {
      const content = list[i].innerHTML;
      const contenlist = content.split(' ');
      console.log(contenlist);
    }
  },
};
</script>

<style scoped>
/*.hf:before {*/
/*  font-weight: 400;*/
/*  color: #2285ff;*/
/*  font-size: 1.5rem;*/
/*  margin-right: 1rem;*/
/*  content: attr(data-content);*/
/*}*/
</style>
